<template>
  <div>
    <h1>We respect your privacy</h1>
    <p>
      Legal: 
      <router-link to="privacypolicy">Privacy Policy</router-link>
      <!-- |
      <router-link to="cookiepolicy">Cookie Policy</router-link> -->
      |
      <router-link to="termsofservice">Terms of Service</router-link>
    </p>

    <div v-if="article" v-html="article.content" class="markdown mx-0 mt-4 dynamic-content"> </div>

  </div>
</template>

<script>

import articleService from "@/services/articleService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "PrivacyPolicy",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      article: null,
    };
  },
  created() {
  },
  async mounted() {
    let article = await articleService.get('privacypolicy');

    if (article == null) {
      // todo: redirect to 404
    }
    if (article != null && tenant.id == 'wmm') {
      article.content = article.content
        .replaceAll('https://sodisp.com', tenant.homeUrl)
        .replaceAll('Sodisp app', tenant.title)
        .replaceAll('https://corporatefitness.app/', tenant.homeUrl)
        ;
    }
    this.article = article;
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" >
  .markdown {
    h1 { display: none;}
    h2 { font-size: 1.2em;}
  }
</style>